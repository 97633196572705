import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../components/loading/Loading";
import {
  addEmployeeWorkRecord,
  createEmployeeNote,
  deleteEmployeeNote,
  getEmployeeDetail,
  updateEmployeeNote,
} from "../../../services/EmployeesServices";
import AcceptedRecord from "./workRecord/AcceptedRecord";
import ButtonLoading from "../../../components/loading/ButtonLoading";
import WaiteRecord from "./workRecord/WaiteRecord";
import { Button } from "react-bootstrap";
import { Grid, IconButton, MenuItem, Select, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CreateIcon from "@mui/icons-material/Create";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { calculateTimeDifference } from "../../../help/function";

const EmployeeWorkRecord = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [classScope, setClassScope] = useState("container");
  const [employee, setEmployee] = useState();
  const [selectedDate, setSelectedDate] = useState(getCurrentMonthYear());
  const [employeeNote, setEmployeeNote] = useState([]);
  const [isMobile, setIsMobile] = useState(false);

  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const currentMonth = new Date().getMonth() + 1;
  const [activeButton, setActiveButton] = useState(currentMonth);
  const [rerender, setRerender] = useState("");
  const [formData, setFormData] = useState({
    work_date: new Date().toISOString().substr(0, 10),
    start_time: "",
    end_time: "",
    overtime: "",
    work_content: "",
    travel_expense: 0,
    daily_wage: 0,
    hours_worked: "",
  });

  const [manualHoursWorked, setManualHoursWorked] = useState("");
  const workDateRef = useRef();
  const partnerIdRef = useRef();
  const startTimeRef = useRef();
  const assemblyTimeRef = useRef();
  const endTimeRef = useRef();
  const workContentRef = useRef();
  const travelExpenseRef = useRef();
  const dailyWageRef = useRef();
  const hoursWorkedRef = useRef();
  const overtimeRef = useRef();
  const months = [
    "1月",
    "2月",
    "3月",
    "4月",
    "5月",
    "6月",
    "7月",
    "8月",
    "9月",
    "10月",
    "11月",
    "12月",
  ];
  function getCurrentMonthYear() {
    return new Date().toISOString().slice(0, 7);
  }

  useEffect(() => {
    // Kiểm tra kích thước màn hình và thay đổi classScope
    if (window.innerWidth < 1000) {
      setClassScope("");
    }
  }, []);

  const formRef = useRef(null);
  // Lưu thông tin từ form nhập công việc
  const handleSubmit = async (event) => {
    event.preventDefault(); // Ngăn chặn gửi form mặc định
    setButtonLoading(true);
    const inputData = {
      work_date: workDateRef.current.value,
      partner_id: partnerIdRef.current.value,
      assemb_time: assemblyTimeRef.current.value,
      start_time: startTimeRef.current.value,
      end_time: endTimeRef.current.value,
      over_time: overtimeRef.current.value || "00:00",
      work_content: workContentRef.current.value,
      travel_expense: travelExpenseRef.current.value,
      daily_wage: dailyWageRef.current.value,
      hours_worked: manualHoursWorked,
    };
    await addEmployeeWorkRecord(id, inputData)
      .then((response) => {
        console.log("Xử lý thành công");
      })
      .catch((error) => {
        console.error(error);
      });
    setRerender("render");
  };

  //Start time
  function handleStartTimeChange() {
    const startTime = startTimeRef.current.value;
    const endTime = endTimeRef.current.value;
    const hoursWorked = calculateTimeDifference(startTime, endTime);
    setFormData({
      ...formData,
      start_time: startTime,
      hours_worked: hoursWorked,
    });
  }

  //End time
  function handleEndTimeChange() {
    const startTime = startTimeRef.current.value;
    const endTime = endTimeRef.current.value;
    const hoursWorked = calculateTimeDifference(startTime, endTime);
    setManualHoursWorked(hoursWorked);
    setFormData({ ...formData, end_time: endTime, hours_worked: hoursWorked });
  }
  //Xử lý thêm số giờ làm việc bằng tay
  function handleManualHoursWorkedChange() {
    const hoursWorked = hoursWorkedRef.current.value;
    setManualHoursWorked(hoursWorked);
    setFormData({ ...formData, hours_worked: hoursWorked });
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  //fetch data
  const fetchEmployeeDetail = async () => {
    try {
      const inputData = {
        date: selectedDate,
      };
      const data = await getEmployeeDetail(id, inputData);
      setEmployee(data.employees);
      if (data.note) {
        setEmployeeNote(data.note);
        setContent(data.note.content);
        setTag(data.note.tag);
      }
      setLoading(false);
      setData(data);
    } catch (err) {
      console.error(err);
    }
  };

  // Fetch data khi component được render hoặc khi có sự thay đổi trong selectedDate
  useEffect(() => {
    fetchEmployeeDetail();
    setButtonLoading(false);
    setRerender("");
  }, [rerender]);

  // Handler khi chọn tháng
  const handleGetWorkRecode = async (e) => {
    const parsedMonth = parseInt(e, 10);
    setActiveButton(parsedMonth);
    setSelectedDate(selectedYear + "-" + e);
    setRerender("click");
    setFormData({
      work_date: "",
      start_time: "",
      end_time: "",
      overtime: "",
      work_content: "",
      travel_expense: 0,
      daily_wage: 0,
      hours_worked: "",
    });
    setLoading(true);
  };
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const years = [currentYear - 1, currentYear, currentYear + 1];

  const handleYearChange = (event) => {
    const selectedYear = parseInt(event.target.value);
    setSelectedYear(selectedYear);
  };
  const [tag, setTag] = useState("");
  const [content, setContent] = useState("");

  const [isOpenNote, setIsOpenNote] = useState(false);
  const handleAddNote = async () => {
    setNoteSaveButtonLoading(true);
    let inputData = {
      tag: tag,
      content: content,
      addDate: selectedDate,
    };
    try {
      const response = await createEmployeeNote(id, inputData);
    } catch (err) {
      console.log(err);
    }
    setRerender("addNote");
    setNoteSaveButtonLoading(false);
  };

  const onUpdateNote = async (id) => {
    let inputData = {
      tag: tag,
      content: content,
    };
    try {
      const response = await updateEmployeeNote(id, inputData);
      setEditIndex();
      setRerender("onUpdateNote");
    } catch (err) {
      console.log(err);
    }
  };
  const onDeleteNote = async (id) => {
    try {
      const response = await deleteEmployeeNote(id);
      setEditIndex();
      setRerender("onUpdateNote");
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeTag = (value) => {
    setTag(value);
  };
  const handleChangeContent = (value) => {
    setContent(value);
  };

  const [editIndex, setEditIndex] = useState(null);
  const [noteSaveButtonLoading, setNoteSaveButtonLoading] = useState(false);

  const handleOpentEditRow = (index) => {
    setEditIndex(index);
  };
  const handleCloseEditRow = () => {
    setEditIndex();
  };

  // Xác định và thay đổi kích thước màn hình
  useEffect(() => {
    if (window.innerWidth < 1000) {
      setIsMobile(true);
    }
  }, []);

  if (!data) return <Loading />;

  return (
    <>
      <div className="hnh-em py-5 profile-section">
        <div className="container">
          {data && (
            <div className="row">
              <div className="col-md-3 col-xs-12 col hnh-image">
                {data.employees.avatar ? (
                  <>
                    <img
                      src={`${data.employees.avatar}`}
                      alt="Employee Photo"
                    />
                  </>
                ) : (
                  <>
                    <img src="../img/noavatar.png" alt="Employee Photo" />
                  </>
                )}
              </div>
              <div className="col-md-9 col-xs-12 col hnh-details">
                <h2>氏名：{employee.name}</h2>
                <a
                  className="btn btn-outline-secondary active"
                  href={`/employeeworks/${employee.id}`}
                >
                  稼働状況
                </a>
                <a
                  className="btn btn-outline-secondary"
                  href={`/employees/${employee.id}`}
                >
                  社員プロフィール
                </a>
                <div className="khuyen-mai">
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <h3 className="hnh-title-h3">稼働状況</h3>
                      <div className="form-group">
                        <ul>
                          <li>
                            勤務時間総数 :{" "}
                            {data.total_hours_worked &&
                              data.total_hours_worked.total_hours_worked}{" "}
                            時間
                          </li>
                          <li>
                            出勤回数 : {data.word_day && data.word_day.word_day}{" "}
                            回
                          </li>
                          <li>
                            給料 :{" "}
                            {data.daily_wage &&
                              data.daily_wage.total_daily_wage}{" "}
                            円
                          </li>
                          <li>
                            交通費 :{" "}
                            {data.travel_expense &&
                              data.travel_expense.total_travel_expense}{" "}
                            円
                          </li>
                        </ul>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <h3 className="hnh-title-h3">メモ</h3>
                      <div className="form-group">
                        <button
                          className="btn btn-outline-secondary"
                          onClick={() => setIsOpenNote(!isOpenNote)}
                        >
                          {isOpenNote ? "閉じる" : "追加"}
                        </button>
                        <ul>
                          {isOpenNote && (
                            <li>
                              <input
                                type="text"
                                className="form-control"
                                name="work_content"
                                required
                                onChange={(e) =>
                                  handleChangeTag(e.target.value)
                                }
                              />
                              <input
                                type="text"
                                className="form-control"
                                name="work_content"
                                required
                                onChange={(e) =>
                                  handleChangeContent(e.target.value)
                                }
                              />
                              <IconButton
                                disabled={!tag || !content}
                                size="small"
                                onClick={handleAddNote}
                              >
                                {noteSaveButtonLoading ? (
                                  <ButtonLoading />
                                ) : (
                                  "保存"
                                )}
                              </IconButton>
                            </li>
                          )}
                          {employeeNote &&
                            employeeNote.map((note, index) => {
                              return (
                                <li key={index}>
                                  {index === editIndex ? (
                                    <>
                                      <TextField
                                        defaultValue={note.tag}
                                        onChange={(e) =>
                                          handleChangeTag(e.target.value)
                                        }
                                        id="standard-basic"
                                        variant="standard"
                                        require
                                      />
                                      <TextField
                                        defaultValue={note.content}
                                        onChange={(e) =>
                                          handleChangeContent(e.target.value)
                                        }
                                        id="standard-basic"
                                        variant="standard"
                                        require
                                      />
                                      <IconButton
                                        size="small"
                                        onClick={() => onUpdateNote(note.id)}
                                      >
                                        {noteSaveButtonLoading ? (
                                          <ButtonLoading />
                                        ) : (
                                          "保存"
                                        )}
                                      </IconButton>
                                      <IconButton
                                        onClick={handleCloseEditRow}
                                        size="small"
                                      >
                                        <CloseIcon fontSize="inherit" />
                                      </IconButton>
                                    </>
                                  ) : (
                                    <>
                                      {note.tag} : {note.content}
                                      <IconButton size="small">
                                        <CreateIcon
                                          onClick={() =>
                                            handleOpentEditRow(index)
                                          }
                                          fontSize="inherit"
                                        />
                                      </IconButton>
                                      <IconButton
                                        size="small"
                                        onClick={() => onDeleteNote(note.id)}
                                      >
                                        削除
                                      </IconButton>
                                    </>
                                  )}
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={`${classScope}`}>
        <div className="row">
          <div className="col-xs-12">
            <Select value={selectedYear} onChange={handleYearChange}>
              {years.map((year, index) => (
                <MenuItem key={index} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
            {months.map((month, index) => (
              <Button
                className={`button-month ${
                  activeButton === index + 1 ? "active" : ""
                }`}
                key={index}
                onClick={() =>
                  handleGetWorkRecode(String(index + 1).padStart(2, "0"))
                }
                value={index + 1}
              >
                {month}
              </Button>
            ))}
            <form ref={formRef} onSubmit={handleSubmit}>
              <table className="table hnh-table-em">
                <thead>
                  <tr>
                    <th scope="col">日付</th>
                    <th scope="col">社名</th>
                    <th scope="col">集合</th>
                    <th scope="col">開始</th>
                    <th scope="col">終了</th>
                    <th scope="col">残業</th>
                    <th scope="col">内容</th>
                    <th scope="col">給与（円）</th>
                    <th scope="col">交通費（円）</th>
                    <th scope="col">実務時間</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <input
                        className="form-control"
                        name="work_date"
                        type="date"
                        required
                        value={formData.work_date}
                        ref={workDateRef}
                        onChange={handleChange}
                      />
                    </th>
                    <td>
                      <select
                        name="partner_id"
                        className="form-select"
                        aria-label="Default select example"
                        ref={partnerIdRef}
                        value={formData.partner_id}
                        onChange={handleChange}
                      >
                        <option value={0}>-- なし --</option>
                        {data &&
                          data.partner &&
                          data.partner.map((partner) => {
                            return (
                              <option value={partner.id}>
                                {partner.partnerName}
                              </option>
                            );
                          })}
                      </select>
                    </td>
                    <td>
                      <input
                        type="time"
                        className="form-control"
                        name="assemb_time"
                        ref={assemblyTimeRef}
                        required
                        value={formData.assemb_time}
                        onChange={handleStartTimeChange}
                      />
                    </td>
                    <td>
                      <input
                        type="time"
                        className="form-control"
                        name="start_time"
                        ref={startTimeRef}
                        required
                        value={formData.start_time}
                        onChange={handleStartTimeChange}
                      />
                    </td>
                    <td>
                      <input
                        type="time"
                        className="form-control"
                        name="end_time"
                        ref={endTimeRef}
                        required
                        value={formData.end_time}
                        onChange={handleEndTimeChange}
                      />
                    </td>
                    <td>
                      <input
                        type="time"
                        className="form-control"
                        name="overtime"
                        ref={overtimeRef}
                        value={formData.overtime}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        name="work_content"
                        ref={workContentRef}
                        required
                        value={formData.work_content}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        name="daily_wage"
                        ref={dailyWageRef}
                        required
                        value={formData.daily_wage}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        name="travel_expense"
                        ref={travelExpenseRef}
                        required
                        value={formData.travel_expense}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <div className="input-group mb-3">
                        <input
                          type="time"
                          className="form-control"
                          name="hours_worked"
                          ref={hoursWorkedRef}
                          required
                          value={formData.hours_worked}
                          onChange={handleManualHoursWorkedChange}
                        />
                      </div>
                      <button
                        className="btn btn-outline-secondary"
                        type="submit"
                      >
                        {buttonLoading ? <ButtonLoading /> : "保存"}
                      </button>
                    </td>
                    <td></td>
                  </tr>
                  {loading ? (
                    <>
                      <Loading />
                    </>
                  ) : (
                    <>
                      {data.wait_record &&
                        data.wait_record.map((work) => {
                          return (
                            <>
                              <WaiteRecord
                                work={work}
                                setRerender={setRerender}
                                partners={data.partner}
                              />
                            </>
                          );
                        })}
                      {data.employee_work &&
                        data.employee_work.map((work) => {
                          return (
                            <>
                              <AcceptedRecord
                                work={work}
                                setRerender={setRerender}
                                partners={data.partner}
                              />
                            </>
                          );
                        })}
                    </>
                  )}
                </tbody>
              </table>
            </form>
            {isMobile && (
              <Button className="fixedButton" onClick={() => navigate(-1)}>
                <ArrowBackIcon />
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeWorkRecord;
