import './App.css';
import Home from './pages/admin/Home';
import { BrowserRouter,Routes, Route, Navigate, useNavigate } from "react-router-dom"
import Employees from './pages/admin/employee/Employess';
import EmployeeDetail from './pages/admin/employee/EmployeeDetail';
import Partner from './pages/admin/partner/Partner';
import Layout from './pages/admin/layout/Layout';
import Company from './pages/admin/company/Company';
import User from './pages/admin/user/User';
import CompanyEmployee from './pages/admin/company/CompanyEmployee';
import LoginForm from './pages/login/Login';
import { useContext } from 'react';
import { AuthContext } from './context/AuthContext';
import EmployeeWorkRecord from './pages/admin/employee/EmployeeWorkRecord';

function ProtectedRoute({ children }) {
  const { user, loading } = useContext(AuthContext);
  let navigate = useNavigate();
  const local = localStorage.getItem("user")
  if (loading) {
    return null; // Or return a loading spinner
  }
  
  if (!user||!local) {
    navigate('/login');
    return null;
  }
  
  return children;
}

function App() {

  
  return (
    <BrowserRouter>
        <div className="App">
        <Routes>
          <Route path="/login" element={<LoginForm />} />
          <Route path="/" element={<Layout />}>
          <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
                <Route path="/employees" element={<Employees />} />
                <Route path="/company/:id" element={<CompanyEmployee />} />
                <Route path="/partner/:id" element={<Partner />} />
                <Route path="/employees/:id" element={<EmployeeDetail />} />
                <Route path="/company" element={<Company />} />
                <Route path="/user" element={<User />} />
                <Route path="/employeeworks/:id" element={<EmployeeWorkRecord />} />
          </Route>
        </Routes>
    </div>
    </BrowserRouter>
  );
}

export default App;
