import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import {
  employeeDeleteWorkRecord,
  getEmployeeDetail,
} from "../../../../services/EmployeesServices";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import BackspaceIcon from "@mui/icons-material/Backspace";
import ButtonLoading from "../../../../components/loading/ButtonLoading";
import AssistantPhotoIcon from "@mui/icons-material/AssistantPhoto";

const AcceptedRecord = ({ work, setRerender, partners }) => {
  const [manualHoursWorked, setManualHoursWorked] = useState("");
  const workDateRef = useRef();
  const partnerIdRef = useRef();
  const startTimeRef = useRef();
  const assemblyTimeRef = useRef();

  const endTimeRef = useRef();
  const workContentRef = useRef();
  const travelExpenseRef = useRef();
  const dailyWageRef = useRef();
  const hoursWorkedRef = useRef();
  const [buttonLoading, setButtonLoading] = useState(false);
  const overtimeRef = useRef(work.over_time);
  const { id } = useParams();
  const [editWork, setEditWork] = useState(false);

  const [formData, setFormData] = useState({
    work_date: work.work_date,
    start_time: work.start_time,
    end_time: work.end_time,
    overtime: work.over_time,
    work_content: work.work_content,
    travel_expense: work.travel_expense,
    daily_wage: work.daily_wage,
    hours_worked: work.hours_worked,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  function handleStartTimeChange() {
    const startTime = startTimeRef.current.value;
    const endTime = endTimeRef.current.value;
    const hoursWorked = calculateHoursWorked(startTime, endTime);
    setFormData({
      ...formData,
      start_time: startTime,
      hours_worked: hoursWorked,
    });
  }

  function handleEndTimeChange() {
    const startTime = startTimeRef.current.value;
    const endTime = endTimeRef.current.value;

    const hoursWorked = calculateHoursWorked(startTime, endTime);
    setManualHoursWorked(hoursWorked);
    setFormData({ ...formData, end_time: endTime, hours_worked: hoursWorked });
  }

  function handleManualHoursWorkedChange() {
    const hoursWorked = hoursWorkedRef.current.value;
    setManualHoursWorked(hoursWorked);
    setFormData({ ...formData, hours_worked: hoursWorked });
  }

  function calculateHoursWorked(startTime, endTime) {
    const start = new Date(`2023-01-01T${startTime}`);
    const end = new Date(`2023-01-01T${endTime}`);
    const diff = end.getTime() - start.getTime();
    const minutes = Math.floor(diff / (1000 * 60));
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const hoursWorked = `${hours}:${remainingMinutes}`;

    return hoursWorked;
  }

  const deleteRecord = async () => {
    setButtonLoading(true);
    try {
      const response = await employeeDeleteWorkRecord(work.id);
      setRerender("deleteRecord");
      setButtonLoading(false);
    } catch (err) {
      console.log(err);
      setRerender("deleteRecord-err");
      setButtonLoading(false);
    }
  };

  return (
    <>
      {editWork ? (
        <>
          <tr>
            <th scope="row">
              <input
                className="form-control"
                name="work_date"
                type="date"
                value={formData.work_date}
                ref={workDateRef}
                onChange={handleChange}
              />
            </th>
            <td>
              <select
                name="partner_id"
                className="form-select"
                aria-label="Default select example"
                ref={partnerIdRef}
                value={formData.partner_id}
                onChange={handleChange}
              >
                <option value="">-- なし --</option>
                {partners &&
                  partners.map((partner) => {
                    return (
                      <option value={partner.id}>{partner.partnerName}</option>
                    );
                  })}
              </select>
            </td>
            <td>
              <input
                type="time"
                className="form-control"
                name="assemb_time"
                ref={assemblyTimeRef}
                defaultValue={work.assemb_time}
                value={formData.assemb_time}
                onChange={handleStartTimeChange}
              />
            </td>
            <td>
              <input
                type="time"
                className="form-control"
                name="start_time"
                ref={startTimeRef}
                defaultValue={work.start_time}
                value={formData.start_time}
                onChange={handleStartTimeChange}
              />
            </td>
            <td>
              <input
                type="time"
                className="form-control"
                name="end_time"
                ref={endTimeRef}
                defaultValue={work.end_time}
                value={formData.end_time}
                onChange={handleEndTimeChange}
              />
            </td>
            <td>
              <input
                type="time"
                className="form-control"
                name="overtime"
                ref={overtimeRef}
                value={formData.overtime}
                onChange={handleChange}
              />
            </td>
            <td>
              <input
                type="text"
                className="form-control"
                name="work_content"
                ref={workContentRef}
                value={formData.work_content}
                onChange={handleChange}
              />
            </td>
            <td>
              <input
                type="text"
                className="form-control"
                name="daily_wage"
                ref={dailyWageRef}
                value={formData.daily_wage}
                onChange={handleChange}
              />
            </td>
            <td>
              <input
                type="text"
                className="form-control"
                name="travel_expense"
                ref={travelExpenseRef}
                value={formData.travel_expense}
                onChange={handleChange}
              />
            </td>
            <td>
              <div className="input-group mb-3">
                <input
                  type="time"
                  className="form-control"
                  name="hours_worked"
                  ref={hoursWorkedRef}
                  defaultValue={work.hours_worked}
                  value={manualHoursWorked}
                  onChange={handleManualHoursWorkedChange}
                />

                <IconButton size="small">保存</IconButton>
                <IconButton size="small">
                  <CloseIcon
                    fontSize="inherit"
                    onClick={() => setEditWork(!editWork)}
                  />
                </IconButton>
              </div>
            </td>
          </tr>
        </>
      ) : (
        <>
          <tr>
            <th scope="row">{work.work_date}</th>
            <td>{work.partnerName}</td>
            <td>
              {work.assemb_time}
              <td>
                {work.assembLatitude && (
                  <a
                    target="_blank"
                    href={`https://www.google.com/maps/search/?api=1&query=${work.assembLatitude},${work.assembLatitude}`}
                  >
                    <AssistantPhotoIcon />
                  </a>
                )}
              </td>
            </td>
            <td>
              {work.start_time}
              <td>
                {work.endLatitude && (
                  <a
                    target="_blank"
                    href={`https://www.google.com/maps/search/?api=1&query=${work.latitude},${work.longitude}`}
                  >
                    <AssistantPhotoIcon />
                  </a>
                )}
              </td>
            </td>
            <td>
              {work.end_time}
              <td>
                {work.endLatitude && (
                  <a
                    target="_blank"
                    href={`https://www.google.com/maps/search/?api=1&query=${work.endLatitude},${work.endLongitude}`}
                  >
                    <AssistantPhotoIcon />
                  </a>
                )}
              </td>
            </td>
            <td>{work.over_time}</td>
            <td>{work.work_content}</td>
            <td>{parseFloat(work.daily_wage).toFixed(2).split(".")[0]}</td>
            <td>{parseFloat(work.travel_expense).toFixed(2).split(".")[0]}</td>
            <td>
              <div className="input-group mb-3" style={{ width: "100%" }}>
                {work.hours_worked}
              </div>
            </td>
            <IconButton
              onClick={deleteRecord}
              style={{ marginRight: "1px" }}
              size="small"
            >
              {buttonLoading ? (
                <ButtonLoading />
              ) : (
                <BackspaceIcon fontSize="inherit" />
              )}
            </IconButton>
          </tr>
        </>
      )}
    </>
  );
};

export default AcceptedRecord;
