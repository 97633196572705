import { useEffect, useRef, useState } from "react";
import Loading from "../../../components/loading/Loading";
import Button from "react-bootstrap/Button";
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
  TableBody,
  Tabs,
  Tab,
  Box,
  Alert,
  Stack,
  Pagination,
} from "@mui/material";
import Form from "react-bootstrap/Form";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { InputGroup } from "react-bootstrap";
import { useParams } from "react-router";
import { addTeam, getTeam, updateTeam } from "../../../services/TeamService";
import {
  addEmployee,
  deleteEmployee,
} from "../../../services/EmployeesServices";
import "./style.css";
import { getEmployees } from "../../../services/CompanyService";
import CreateIcon from "@mui/icons-material/Create";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import TeamDetail from "./teams/TeamDetail";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CompanyEmployee = () => {
  const { id } = useParams();
  const [data, setData] = useState();
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [limit, setLimit] = useState(5);
  const timeout = useRef(null);
  const [loading, setLoading] = useState(true);
  const [isAddEmployee, setIsAddEmpoyee] = useState(false);
  const [rerender, setRerender] = useState("");
  const nameRef = useRef(null);
  const teamName = useRef("");
  const ageRef = useRef(null);
  const departmentRef = useRef(null);
  const phoneRef = useRef(null);
  const [teamId, setTeamId] = useState(0);
  const [editTeam, setEditTeam] = useState(false);
  const [value, setValue] = useState(0);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    const fetchTeam = async () => {
      try {
        const response = await getTeam(id);
        setTeams(response);
        const inputData = {
          page: page,
          limit: limit,
        };
        const response2 = await getEmployees(id, inputData);
        setData(response2.data);
        setPages(response2.pages);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };

    timeout.current = setTimeout(() => {
      setLoading(false);
    }, 10000);

    fetchTeam();
    return () => {
      clearTimeout(timeout.current);
    };
  }, [rerender, page]);

  const onHandleAddEmployee = async () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().slice(0, 10);
    const inputData = {
      company_id: id,
      name: nameRef.current.value,
      department: departmentRef.current.value,
      phone_number: phoneRef.current.value,
      team_id: teamId,
      nowdate: formattedDate,
    };

    try {
      await addEmployee(inputData);
      setRerender("response");
      setIsAddEmpoyee(false);
      window.location.reload();
    } catch (err) {
      return err;
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (teams.length > 0) {
      if (newValue > 0 && newValue <= teams.length) {
        setTeamId(teams[newValue - 1].id);
      }
    }
  };

  const onHandleEditTeamName = async () => {
    const inputData = { team_name: teamName.current.value };
    try {
      const update = await updateTeam(teamId, inputData);
      setRerender("response");
      setEditTeam(!editTeam);
      window.location.reload();
    } catch (err) {
      console.log(err);
      setRerender("response");
      window.location.reload();
    }
  };

  const onHandleSave = async () => {
    const inputData = {
      company_id: id,
      team_name: teamName.current.value,
    };
    try {
      await addTeam(inputData);
      setRerender("response");
      window.location.reload();

      setLoading(false);
    } catch (err) {
      console.log(err);
      setRerender("response");
      window.location.reload();
    }
  };

  const onDeleteEmployee = async (id) => {
    await deleteEmployee(id);
    setRerender("delete");
  };

  const handleDeleteClick = (employee) => {
    confirmAlert({
      title: "確認",
      message: `${employee.name}さんを削除しますか？`,
      buttons: [
        {
          label: "確確",
          onClick: () => {
            onDeleteEmployee(employee.id);
          },
        },
        {
          label: "キャンセル",
          onClick: () => {},
        },
      ],
    });
  };

  if (loading) return <Loading />;

  return (
    <>
      <div className="container-xxl p-0">
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="全従業員" {...a11yProps(0)} />
            {teams &&
              teams.map((team, index) => {
                return <Tab label={team.team_name} {...a11yProps(index + 1)} />;
              })}
            <Tab label="チーム管理" {...a11yProps(4)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          {isAddEmployee ? (
            <Button
              id="add-employee-btn"
              onClick={() => setIsAddEmpoyee(false)}
            >
              閉じる
            </Button>
          ) : (
            <Button id="add-employee-btn" onClick={() => setIsAddEmpoyee(true)}>
              追加
            </Button>
          )}
          {isAddEmployee && (
            <>
              <div className="khuyen-mai">
                <ul>
                  <li>
                    <strong>氏名:</strong>
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      inputRef={nameRef}
                      require
                    />
                  </li>
                  <li>
                    <strong>所属先:</strong>
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      inputRef={departmentRef}
                      require
                    />
                  </li>
                  <li>
                    <strong>電話番号:</strong>
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      inputRef={phoneRef}
                      require
                    />
                  </li>
                </ul>
                <Button variant="success" onClick={onHandleAddEmployee}>
                  保存
                </Button>
              </div>
            </>
          )}

          {data &&
            data.map((row) => (
              <div className="col-lg-12">
                <div className="hnh-list-employees position-relative rounded overflow-hidden">
                  <div className="portfolio-overlay">
                    <div className="mt-auto">
                      <div className="card">
                        <div>
                          <Tooltip
                            title="Delete"
                            className="position-absolute top-0 end-0 mt-3 me-3"
                          >
                            <IconButton onClick={() => handleDeleteClick(row)}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                        <a href={`/employeeworks/${row.id}`}>
                          <div className="card-body d-flex">
                            {row.avatar !== null ? (
                              <>
                                <div className="avatar-wrapper">
                                  <img
                                    src={`${row.avatar}`}
                                    className="avatar-img"
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="avatar-wrapper">
                                  <img
                                    src="../img/noavatar.png"
                                    className="avatar-img"
                                  />
                                </div>
                              </>
                            )}
                            <div>
                              <h5 className="card-title">
                                氏名：
                                {row.name}
                              </h5>
                              <p className="card-text">
                                所属先：
                                {row.department}
                              </p>
                              <p className="card-text">
                                年齢：{" "}
                                {row.birth_date &&
                                  new Date().getFullYear() -
                                    new Date(row.birth_date).getFullYear()}
                              </p>

                              <p className="card-text">
                                電話番号： {row.phone_number}
                              </p>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </TabPanel>
        {teams &&
          teams.map((team, index) => {
            return (
              <TabPanel value={value} index={index + 1}>
                <div className="section-title position-relative text-center mb-5 pb-2">
                  <h3 className="position-relative d-inline text-primary ps-4">
                    {editTeam ? (
                      <>
                        <TextField
                          defaultValue={team.team_name}
                          id="standard-basic"
                          variant="standard"
                          inputRef={teamName}
                          require
                        />
                        <IconButton onClick={onHandleEditTeamName} size="small">
                          保存
                        </IconButton>
                        <IconButton
                          onClick={() => setEditTeam(!editTeam)}
                          size="small"
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        {team.team_name}
                        <IconButton
                          onClick={() => setEditTeam(!editTeam)}
                          size="small"
                        >
                          <CreateIcon fontSize="inherit" />
                        </IconButton>
                      </>
                    )}
                  </h3>
                </div>
                {isAddEmployee ? (
                  <Button
                    id="add-employee-btn"
                    onClick={() => setIsAddEmpoyee(false)}
                  >
                    閉じる
                  </Button>
                ) : (
                  <Button
                    id="add-employee-btn"
                    onClick={() => setIsAddEmpoyee(true)}
                  >
                    追加
                  </Button>
                )}
                {isAddEmployee && (
                  <>
                    <div className="khuyen-mai">
                      <ul>
                        <li>
                          <strong>氏名:</strong>
                          <TextField
                            id="standard-basic"
                            variant="standard"
                            inputRef={nameRef}
                          />
                        </li>
                        <li>
                          <strong>生年月日:</strong>
                          <TextField
                            type="date"
                            id="standard-basic"
                            variant="standard"
                            inputRef={ageRef}
                          />
                        </li>
                        <li>
                          <strong>所属先:</strong>
                          <TextField
                            id="standard-basic"
                            variant="standard"
                            inputRef={departmentRef}
                          />
                        </li>
                        <li>
                          <strong>電話番号:</strong>
                          <TextField
                            id="standard-basic"
                            variant="standard"
                            inputRef={phoneRef}
                          />
                        </li>
                      </ul>
                      <Button variant="success" onClick={onHandleAddEmployee}>
                        保存
                      </Button>
                    </div>
                  </>
                )}
                {data &&
                  data
                    .filter((data) => {
                      return data.team_id === team.id;
                    })
                    .map((row) => (
                      <div className="col-lg-12">
                        <div>
                          <div>
                            <div className="mt-auto">
                              <a href={`/employees/${row.id}`}>
                                <div className="card">
                                  <div className="card-body d-flex">
                                    {row.avatar !== null ? (
                                      <>
                                        <div className="avatar-wrapper">
                                          <img
                                            src={`${row.avatar}`}
                                            className="avatar-img"
                                          />
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className="avatar-wrapper">
                                          <img
                                            src="../img/noavatar.png"
                                            className="avatar-img"
                                          />
                                        </div>
                                      </>
                                    )}{" "}
                                    <div>
                                      <h5 className="card-title">
                                        氏名：
                                        {row.name}
                                      </h5>
                                      <p className="card-text">所属先：</p>
                                      <p className="card-text">年齢：</p>
                                      <p className="card-text">電話番号：</p>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
              </TabPanel>
            );
          })}

        {teams ? (
          <>
            <TabPanel value={value} index={teams.length + 1}>
              {teams.map((team) => {
                return <TeamDetail data={team} />;
              })}
              <Form>
                <InputGroup className="mb-3">
                  <InputGroup.Text style={{ width: "200px" }}>
                    チーム名
                  </InputGroup.Text>
                  <div className="d-flex">
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      inputRef={teamName}
                    />
                  </div>
                </InputGroup>
                <Button variant="primary" onClick={onHandleSave}>
                  追加
                </Button>
              </Form>
            </TabPanel>
          </>
        ) : (
          <>
            <TabPanel value={value} index={1}>
              <Form>
                <InputGroup className="mb-3">
                  <InputGroup.Text style={{ width: "200px" }}>
                    チーム名
                  </InputGroup.Text>
                  <div className="d-flex">
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      inputRef={teamName}
                    />
                  </div>
                </InputGroup>
                <Button variant="primary" onClick={onHandleSave}>
                  追加
                </Button>
              </Form>
            </TabPanel>
          </>
        )}
      </div>
    </>
  );
};

export default CompanyEmployee;
