import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import {
  AcceptRecord,
  employeeDeleteWorkRecord,
  getEmployeeDetail,
} from "../../../../services/EmployeesServices";
import { IconButton, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CreateIcon from "@mui/icons-material/Create";
import AssistantPhotoIcon from "@mui/icons-material/AssistantPhoto";
import ButtonLoading from "../../../../components/loading/ButtonLoading";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const AcceptWorkRecord = ({ work, setRerender, partners }) => {
  const [manualHoursWorked, setManualHoursWorked] = useState(work.hours_worked);
  const workDateRef = useRef(work.work_date);
  const partnerIdRef = useRef(work.partner_id);
  const startTimeRef = useRef(work.start_time);
  const assemblyTimeRef = useRef(work.assemb_time);
  const endTimeRef = useRef(work.end_time);

  const workContentRef = useRef(work.work_content);
  const travelExpenseRef = useRef(work.travel_expense);
  const dailyWageRef = useRef(work.daily_wage);
  const hoursWorkedRef = useRef(work.hours_worked);
  const [buttonLoading, setButtonLoading] = useState(false);
  const overtimeRef = useRef(work.over_time);
  const { id } = useParams();
  const [startTime, setStartTime] = useState(work.start_time);
  const [endTime, setEndTime] = useState(work.end_time);
  const [assemb_time, setAssemb_time] = useState(work.assemb_time);

  const [editWork, setEditWork] = useState(false);
  const [formData, setFormData] = useState({
    work_date: work.work_date,
    start_time: work.start_time,
    end_time: work.end_time,
    overtime: work.over_time,
    work_content: work.work_content,
    travel_expense: work.travel_expense,
    daily_wage: work.daily_wage,
    hours_worked: work.hours_worked,
    partner_id: work.partner_id,
    assemb_time: work.assemb_time,
    id: work.id,
    work_date: work.work_date,
    latitude: work.latitude,
    longitude: work.longitude,
    endLatitude: work.endLatitude,
    endLongitude: work.endLongitude,
    assembLatitude: work.assembLatitude,
    assembLongitude: work.assembLongitude,
  });

  useEffect(() => {
    setFormData({
      work_date: work.work_date,
      start_time: work.start_time,
      end_time: work.end_time,
      overtime: work.over_time,
      work_content: work.work_content,
      travel_expense: work.travel_expense,
      daily_wage: work.daily_wage,
      hours_worked: work.hours_worked,
      partner_id: work.partner_id,
      assemb_time: work.assemb_time,
      id: work.id,
      work_date: work.work_date,
      latitude: work.latitude,
      longitude: work.longitude,
      endLatitude: work.endLatitude,
      endLongitude: work.endLongitude,
      assembLatitude: work.assembLatitude,
      assembLongitude: work.assembLongitude,
    });
  }, [work]);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  function handleStartTimeChange() {
    const startTime2 = startTimeRef.current.value;
    setStartTime(startTime2);
    const hoursWorked = calculateHoursWorked(startTime2, endTime);
    setManualHoursWorked(hoursWorked);
    setFormData({
      ...formData,
      start_time: startTime2,
      hours_worked: hoursWorked,
    });
  }

  function handleEndTimeChange() {
    const endTime2 = endTimeRef.current.value;
    setEndTime(endTime2);
    const hoursWorked = calculateHoursWorked(startTime, endTime2);
    setManualHoursWorked(hoursWorked);
    setFormData({ ...formData, end_time: endTime2, hours_worked: hoursWorked });
  }

  const handleAssembTimeChange = (event) => {
    const { value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      assemb_time: value,
    }));
  };

  function handleManualHoursWorkedChange() {
    const hoursWorked = hoursWorkedRef.current.value;
    setManualHoursWorked(hoursWorked);
    setFormData({ ...formData, hours_worked: hoursWorked });
  }

  function calculateHoursWorked(startTime, endTime) {
    const format = new Intl.DateTimeFormat("ja", {
      timeStyle: "short",
      hour12: false,
    });

    const start = new Date(`2023-01-01T${startTime}`);
    const end = new Date(`2023-01-01T${endTime}`);

    let diff = end - start - 90 * 60 * 1000;
    if (diff < 0) {
      diff = 0;
    }
    const minutes = Math.floor(diff / (1000 * 60));
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const hoursWorked = `${hours}:${remainingMinutes
      .toString()
      .padStart(2, "0")}`;
    return hoursWorked;
  }

  const handleAcceptRecord = async () => {
    setButtonLoading(true);
    try {
      const response = await AcceptRecord(id, formData);
      setRerender("AcceptWorkRecord");
      setButtonLoading(false);
      setEditWork(false);
    } catch (err) {
      console.log(err);
      setButtonLoading(false);
    }
  };

  return (
    <>
      {editWork ? (
        <>
          <tr style={{ backgroundColor: "#C4F0FC" }}>
            <th scope="row">
              <input
                className="form-control"
                name="work_date"
                type="date"
                value={formData.work_date}
                ref={workDateRef}
                onChange={handleChange}
              />
            </th>
            <td>
              <select
                name="partner_id"
                className="form-select"
                aria-label="Default select example"
                ref={partnerIdRef}
                value={formData.partner_id}
                onChange={handleChange}
              >
                <option value={0}>-- なし --</option>
                {partners &&
                  partners.map((partner) => {
                    return (
                      <option value={partner.id}>{partner.partnerName}</option>
                    );
                  })}
              </select>
            </td>
            <td>
              <input
                type="time"
                className="form-control"
                name="assemb_time"
                value={formData.assemb_time}
                onChange={handleChange}
              />
            </td>
            <td>
              <input
                type="time"
                className="form-control"
                name="start_time"
                ref={startTimeRef}
                value={formData.start_time}
                onChange={handleStartTimeChange}
              />
            </td>
            <td>
              <input
                type="time"
                className="form-control"
                name="end_time"
                ref={endTimeRef}
                value={formData.end_time}
                onChange={handleEndTimeChange}
              />
            </td>
            <td>
              <input
                type="time"
                className="form-control"
                name="overtime"
                ref={overtimeRef}
                value={formData.overtime}
                onChange={handleChange}
              />
            </td>
            <td>
              <input
                type="text"
                className="form-control"
                name="work_content"
                ref={workContentRef}
                pattern="[0-9]*"
                value={formData.work_content}
                onChange={handleChange}
              />
            </td>
            <td>
              <input
                type="text"
                className="form-control"
                name="daily_wage"
                ref={dailyWageRef}
                pattern="[0-9]*"
                value={formData.daily_wage}
                onChange={handleChange}
              />
            </td>
            <td>
              <input
                type="number"
                className="form-control"
                name="travel_expense"
                ref={travelExpenseRef}
                pattern="[0-9]*"
                value={formData.travel_expense}
                onChange={handleChange}
              />
            </td>
            <td>
              <input
                type="time"
                className="form-control"
                name="hours_worked"
                ref={hoursWorkedRef}
                value={formData.hours_worked}
                onChange={handleManualHoursWorkedChange}
              />
              <div className="input-group mb-3">
                <IconButton size="small" onClick={handleAcceptRecord}>
                  承認
                </IconButton>
                <IconButton size="small">
                  <CloseIcon
                    fontSize="inherit"
                    onClick={() => setEditWork(!editWork)}
                  />
                </IconButton>
              </div>
            </td>
            <td></td>
          </tr>
        </>
      ) : (
        <>
          <tr style={{ backgroundColor: "#C4F0FC" }}>
            <th scope="row">{work.work_date}</th>
            <td>{work.partnerName}</td>
            <td>
              {work.assemb_time}
              <td>
                {work.assembLatitude && (
                  <a
                    target="_blank"
                    href={`https://www.google.com/maps/search/?api=1&query=${work.assembLatitude},${work.assembLongitude}`}
                  >
                    <AssistantPhotoIcon />
                  </a>
                )}
              </td>
            </td>
            <td>
              {work.start_time}
              <td>
                {work.latitude && (
                  <a
                    target="_blank"
                    href={`https://www.google.com/maps/search/?api=1&query=${work.latitude},${work.longitude}`}
                  >
                    <AssistantPhotoIcon />
                  </a>
                )}
              </td>
            </td>
            <td>
              {work.end_time}
              <td>
                {work.endLatitude && (
                  <a
                    target="_blank"
                    href={`https://www.google.com/maps/search/?api=1&query=${work.endLatitude},${work.endLongitude}`}
                  >
                    <AssistantPhotoIcon />
                  </a>
                )}
              </td>
            </td>

            {work.isInOutRecord ? (
              <>
                <td colspan="6">出勤中...</td>
              </>
            ) : (
              <>
                <td>{work.over_time}</td>
                <td>{work.work_content && work.work_content}</td>
                <td>
                  {work.daily_wage &&
                    parseFloat(work.daily_wage).toFixed(2).split(".")[0]}
                </td>
                <td>
                  {work.daily_wage &&
                    parseFloat(work.travel_expense).toFixed(2).split(".")[0]}
                </td>
                <td>
                  <div className="input-group mb-3" style={{ width: "100%" }}>
                    {work.hours_worked}
                  </div>
                </td>
                <td>
                  <IconButton
                    style={{ marginRight: "1px" }}
                    size="small"
                    onClick={handleAcceptRecord}
                  >
                    {buttonLoading ? (
                      <ButtonLoading />
                    ) : (
                      <Tooltip title="承認">
                        <CheckCircleOutlineIcon
                          fontSize="inherit"
                          style={{ color: "green" }}
                        />
                      </Tooltip>
                    )}
                  </IconButton>
                  <IconButton size="small">
                    <CreateIcon
                      className="CreateIcon"
                      fontSize="inherit"
                      onClick={() => setEditWork(!editWork)}
                    />
                  </IconButton>
                </td>
              </>
            )}
          </tr>
        </>
      )}
    </>
  );
};

export default AcceptWorkRecord;
