import { useEffect, useRef, useState }from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import UserDetail from './UserDetail';
import { getAllUser } from '../../../services/UserService';
import Loading from '../../../components/loading/Loading';


export default function UserList(company) {

  const [rerender, setRerender]= useState("")
  const [data,setData] = useState(null)
  const company1 = company.company
  const [loading, setLoading] = useState(true)
  const timeout = useRef(null);

  useEffect(()=>{
    const fetChUser = async()=>{
        try{
            const data = await getAllUser()
            setRerender("ok")
            setData(data)
            setLoading(false);

        }catch(err){
            console.error(err)
            setLoading(false);

        }
    }
    timeout.current = setTimeout(() => {
      setLoading(false);
    }, 10000);
  
    fetChUser()
    return () => {
      clearTimeout(timeout.current);
    };
  },[rerender])

  if (loading) {
    return <Loading />;
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>氏名</TableCell>
            <TableCell align="right">アカウント名</TableCell>
            <TableCell align="right">管理会社</TableCell>
            <TableCell align="right">パスワード</TableCell>
            <TableCell align="right">操作</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data&&data.map((row) => (
              <UserDetail user={row} company={company1}/>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}