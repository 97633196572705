import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { confirmAlert } from "react-confirm-alert";
import { deleteTeam } from "../../../../services/TeamService";

const TeamDetail = ({ data }) => {
  const handleDeleteClick = () => {
    confirmAlert({
      title: "確認",
      message: `${data.team_name}を削除したら、${data.team_name}に配属全ての従業員が削除されます。削除しますか？`,
      buttons: [
        {
          label: "確確",
          onClick: () => {
            deleteTeam(data.id);
            window.location.reload();
          },
        },
        {
          label: "キャンセル",
          onClick: () => {},
        },
      ],
    });
  };
  return (
    <div className="hnh-list-employees position-relative rounded overflow-hidden">
      <div className="portfolio-overlay">
        <div className="mt-auto">
          <div className="card">
            <Tooltip
              title="Delete"
              className="position-absolute top-0 end-0 mt-3 me-3"
            >
              <IconButton onClick={() => handleDeleteClick(data.company)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <div className="card-body d-flex">
              <div>
                <h5 className="card-title">{data.team_name}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamDetail;
