import { Outlet, useNavigate } from "react-router"
import Header from "../../../components/Header"
import './Layout.css'
import Box from '@mui/material/Box';
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";

const Layout = () =>{
  const {user} = useContext(AuthContext)
    return(
        <>
        <Box sx={{ flexGrow: 1 }}>
        <Header />
        {user?
        <Outlet />
                :
                <>
                <div className="col-12 d-flex justify-content-center">
                  <h3>
                    まだログイン完成していないため、<br/><a href="/login">再度ログイン</a>してください !</h3>
                </div>
              </>
        }
      </Box>
        </>
    )
}

export default Layout