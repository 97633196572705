import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import { useEffect, useRef, useState } from 'react';
import { IconButton, Table, TableBody, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { deleteCompany } from '../../../services/CompanyService';


const EditCompany =(data,index)=>{
    const [editName,setEditName] = useState(false)
    const companyName = useRef()
    
    const handleDeleteCompany=async(id)=>{
      try{
        await deleteCompany(id)
        window.location.reload()
      }catch(err){
        console.log(err)
      }
    }
    const handleDeleteClick = (company) => {
      confirmAlert({
        title: '確認',
        message: `${data.company.company_full_name}を削除しますか？`,
        buttons: [
          {
            label: '確確',
            onClick: () => {
              handleDeleteCompany(data.company.id)
            }
          },
          {
            label: 'キャンセル',
            onClick: () => {}
          }
        ]
      });
    }
   
    return(
        <>
                   <div className="hnh-list-employees position-relative rounded overflow-hidden">
                       <div className="portfolio-overlay">
                           <div className="mt-auto">
                                <div className="card">
                                <Tooltip title="Delete" className="position-absolute top-0 end-0 mt-3 me-3">
                                         <IconButton  onClick={() => handleDeleteClick(data.company)}>
                                           <DeleteIcon />
                                         </IconButton>
                                       </Tooltip>
                                    <div className="card-body d-flex">
                                      <div>
                                        
                                          <h5 className="card-title">
                                            <a href={`/company/${data.company.id}`} >
                                            {data.company.company_full_name}
                                            </a>
                                          </h5>
                                            <p className="card-text">
                                            従業員数　{data.company.NumberOfPartners}人
                                            </p>
                                            <p className="card-text">
                                            パートナー企業数 {data.company.NumberOfEmployees}
                                            </p>
                                        </div>
                                 </div>
                             </div>
                           </div>
                          </div>
                    </div>
        </>
    )
}
export default EditCompany