import axios from "axios";

const config = require(`../config/${process.env.REACT_APP_ENV}`);

const URL = config.API_URL;

export const getAllCompany = async (id) => {
  if (!id) {
    id = 0;
  }
  try {
    const response = await axios.get(`${URL}/company/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getCompany = async (id) => {
  try {
    const response = await axios.get(`${URL}/company/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const addCompany = async (company) => {
  try {
    const response = await axios.post(`${URL}/company`, company);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getEmployees = async (company_id, inputData) => {
  try {
    const response = await axios.post(
      `${URL}/company/employee/${company_id}`,
      inputData
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deleteCompany = async (company_id) => {
  try {
    const response = await axios.delete(`${URL}/company/${company_id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
