import React, { createContext, useEffect, useState } from 'react';
import { getUser } from '../services/UserService';

export const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  
  const login = async(id) => {
    const response = await getUser(id)
    localStorage.setItem("user",JSON.stringify(response.data))
    setLoading(false)
  };

  useEffect(()=>{
    const local = localStorage.getItem("user")
    const userData = (JSON.parse(local))
    if(local){
      setUser(userData)
      setLoading(false)
    }
  },[])

  return (
    <AuthContext.Provider value={{user,login,loading}}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContextProvider;
