export const calculateTimeDifference = (startTime, endTime) => {
  if (
    startTime === null ||
    startTime === undefined ||
    endTime === null ||
    endTime === undefined
  ) {
    return "00:00"; // hoặc giá trị mặc định khác tùy ý
  }

  if (typeof startTime !== "string" || typeof endTime !== "string") {
    return "00:00";
  }

  const [startHour, startMinute, startSecond = 0] = startTime
    .split(":")
    .map(Number);
  const [endHour, endMinute, endSecond = 0] = endTime.split(":").map(Number);
  // Chuyển đổi giờ, phút và giây thành mili giây
  const startMs = (startHour * 60 * 60 + startMinute * 60 + startSecond) * 1000;
  const endMs = (endHour * 60 * 60 + endMinute * 60 + endSecond) * 1000;

  // Tính khoảng thời gian
  const timeDifferenceMs = endMs - startMs - 90 * 60 * 1000;

  if (timeDifferenceMs < 0) {
    return "00:00";
  }

  // Chuyển đổi thành giờ, phút và giây
  const hours = Math.floor(timeDifferenceMs / (60 * 60 * 1000));
  const minutes = Math.floor(
    (timeDifferenceMs % (60 * 60 * 1000)) / (60 * 1000)
  );
  const seconds = Math.floor((timeDifferenceMs % (60 * 1000)) / 1000);
  // Định dạng chuỗi giờ hh:mm
  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}`;
};
