import { Button, TableCell, TableRow } from "@mui/material"
import { useRef, useState } from "react"
import { updateUser } from "../../../services/UserService"

const UserDetail = (row)=>{
    const user = row.user
    const company = row.company
    const [isEdit, setIsEdit] = useState(false)
    const nameRef=useRef()
    const companyRef = useRef()
    const loginAccRef = useRef()
    const passwordRef= useRef()

    const Alert=()=>{
      alert("全ての項目を入力してください。")
    }
    const onHandleSaveUSer = async()=>{
        if(!nameRef.current.value||!companyRef.current.value||!companyRef.current.value||!passwordRef.current.value){
          return Alert()
        }
        const password = passwordRef.current.value;
        if (password.length < 6) {
          alert('入力したパスワードが短いすぎます！');
          return;
        } else if (/\s/.test(password)) {
          alert('パスワードに空白が入っています！');
          return;
        }
        const inputData={
            name:nameRef.current.value,
            acc_name:loginAccRef.current.value,
            company:companyRef.current.value,
            password:passwordRef.current.value
        }
        try{
          await updateUser(row.user.id,inputData)
          window.location.reload()
        }catch(err){
          console.log(err)
        }
    }

    return(
        <>
        {isEdit ? <>
            <TableRow
              key={user.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              <input type="text" className="form-control" name="start_time" 
                             ref={nameRef}
                             defaultValue={user.name} required/>
              </TableCell>
              <TableCell align="right">
              <input type="text" className="form-control" name="start_time" 
                             ref={loginAccRef}
                             defaultValue={user.acc_name} disabled/>
                </TableCell>

              <TableCell align="right">
              <select name="company_id" className="form-select" aria-label="Default select example"
                ref={companyRef}
                defaultValue={user.company}>
                      <option value={0}>全て</option>
                      {company && company.map((company) => {
                          return (
                              <option value={company.id}>{company.company_full_name}</option>
                          )
                      })}
                  </select>
                </TableCell>
              <TableCell align="right">
                  <input type="password" className="form-control" name="start_time" 
                             ref={passwordRef} required
                             />
                </TableCell>
              <TableCell align="right">
              <Button variant="primary" onClick={onHandleSaveUSer}>
            保存
          </Button>
                <Button onClick={()=>setIsEdit(!isEdit)}>閉じる</Button></TableCell>
            </TableRow>
        </>:<>
        <TableRow
              key={user.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {user.name}
              </TableCell>
              <TableCell align="right">{user.acc_name}</TableCell>
              <TableCell align="right">{user.company_name}</TableCell>
              <TableCell align="right">****************</TableCell>
              <TableCell align="right"><Button onClick={()=>setIsEdit(!isEdit)}>編集</Button></TableCell>
            </TableRow>
        </>}
        </>
    )
}

export default UserDetail