import { TextField } from "@mui/material"
import "./style.css"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { InputGroup } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { addTeam, getTeam } from "../../../services/TeamService";
import Loading from "../../../components/loading/Loading";
import { addCompany } from "../../../services/CompanyService";


function AddCompany(prop) {
  const teamName = useRef();
  const teamDescription = useRef();
  const [teams,setTeams]= useState()
  const [loading,setLoading]= useState(true)
  const [isOpen,setIsOpen]= useState(false)
  const companyNameRef = useRef(null);
  const companyShortName = useRef(null);


  const onHandleSave =async()=>{
    const inputData ={
      company_full_name:companyNameRef.current.value,
      company_short_name:companyShortName.current.value,
    }
    try{
     await addCompany(inputData)
     window.location.reload();
    }catch(err){
      console.log(err)
    }
  }



  return (
    <>
    {isOpen ? <>
        <InputGroup className="mb-3">
          <InputGroup.Text style={{width:'200px'}}>会社名</InputGroup.Text>
          <div className="d-flex">
          <TextField id="standard-basic"
          variant="standard" inputRef={companyNameRef} />
          </div>
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroup.Text style={{width:'200px'}}>簡略名称</InputGroup.Text>
          <div className="d-flex">
          <TextField id="standard-basic"
          variant="standard" inputRef={companyShortName} />
          </div>
        </InputGroup>
        <Button variant="danger" onClick={()=>setIsOpen(!isOpen)}>
          閉じる
        </Button>
        <Button variant="primary" onClick={onHandleSave}>
          追加
        </Button>
    </>
    :<>
        <Button variant="primary" onClick={()=>setIsOpen(!isOpen)}>
          会社追加
        </Button>
    </>}

    </>
    
  );
}

export default AddCompany