
import { useEffect, useRef, useState } from "react"
import { getEmployees,addEmployee,deleteEmployee } from "../../../services/EmployeesServices";
import Loading from "../../../components/loading/Loading";
import Button from 'react-bootstrap/Button';
import { Table, TableCell, TableContainer, TableHead, TableRow, TextField,Paper,TableBody, Tabs, Tab, Box } from "@mui/material";
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { Card, Col, InputGroup } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import './emloyee.css'
import { useParams } from "react-router";
import { getTeam } from "../../../services/TeamService";
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

const Employees = () =>{
    const { id } = useParams();
    const [data,setData] = useState("")
    const [loading,setLoading] = useState(false)
    const [isAddEmployee, setIsAddEmpoyee] = useState(false)
    const [rerender, setRerender]= useState("")
    const nameRef = useRef(null);
    const ageRef = useRef(null);
    const departmentRef = useRef(null);
    const phoneRef = useRef(null);

    const [teams,setTeams]= useState()
    const [isAddTeam,setIsAddTeam]= useState(false)
    const [isReRender,setIsReRender]= useState()
  
    useEffect(()=>{
      const fetchTeam = async()=>{
        try{
          const response = await getTeam(id)
          setTeams(response)
        }catch(err){
          console.log(err)
        }
      }
      fetchTeam()
    },[isReRender])


      const rows = [
        createData('レ　バン　アン1', 200,20, "警備"),
        createData('レ　バン　アン2', 150, 19.0, "事務"),
        createData('レ　バン　アン3', 140, 16.0,"警備"),
        createData('レ　バン　アン4', 120, 13.7, "警備"),
        createData('レ　バン　アン5', 10, 6.0,"警備"),
      ];
      const [value, setValue] = useState(0);

      const handleChange = (event, newValue) => {
        setValue(newValue);
      };
      if(loading) return <Loading />

    return(
        <>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="全従業員" {...a11yProps(0)} />
            {teams&&
            teams.map((team,index)=> {
              return(
                <Tab label={team.team_name} {...a11yProps(index+1)} />
              )
            })
            }
            <Tab label="従業員追加" {...a11yProps(4)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell><strong>氏名:</strong></TableCell>
                    <TableCell align="right"><strong>所属チーム</strong></TableCell>
                    <TableCell align="right"><strong>勤務時間数</strong></TableCell>
                    <TableCell align="right"><strong>勤務日数</strong></TableCell>
                    <TableCell align="right"><strong>所属</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {isAddEmployee?<button id="add-employee-btn" onClick={() => setIsAddEmpoyee(false)}>閉じる</button>
                    : <button id="add-employee-btn" onClick={() => setIsAddEmpoyee(true)}>追加</button>}
                                        {isAddEmployee&&
                    <>
                        <div className="card">
                           <div className="form-control">
                            <ul>
                                <li>
                                    
                                    <TextField  id="standard-basic" variant="standard" inputRef={nameRef} />
                                </li>
                                <li>
                                    <strong>生年月日:</strong>
                                    <TextField type="date" id="standard-basic" variant="standard" inputRef={ageRef} />
                                </li>
                                <li>
                                    <strong>所属先:</strong>
                                    <TextField  id="standard-basic" variant="standard" inputRef={departmentRef} />
                                </li>
                                <li>
                                    <strong>電話番号:</strong>
                                    <TextField  id="standard-basic" variant="standard" inputRef={phoneRef} />
                                </li>
                            </ul>
                           <Button variant="success" >保存</Button>
                         </div>
                        </div>
                    </>}
                  {rows.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                      <div className="container px-lg-5">
                    <div className="row g-12 portfolio-container" id="employees-list">
                            <div className="col-lg-12 col-md-6 portfolio-item first">
                                <div className="hnh-list-employees position-relative rounded overflow-hidden">
                                    <div className="portfolio-overlay">
                                        <div className="mt-auto">

                                        {data&&data.map((data)=>{return(
                                                <div key={data.id} className="card">
                                                    <div className="card-body d-flex">
                                                    <button className="close-btn" >X</button>
                                                    <img src="../img/noavatar.png" className="avatar-list"/>
                                                    <a href={`/employees/${data.id}`}>
                                                        <div>
                                                            <h5 className="card-title">氏名：{data.name}
                                                            </h5>
                                                            <p className="card-text">所属先：{data.department}
                                                            </p>
                                                            <p className="card-text">年齢：{data.age}
                                                            </p>
                                                            <p className="card-text">電話番号：{data.phone_number}
                                                            </p>
                                                        </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            )
                                            })} 
                                        </div>
                                    </div>
                                </div>
                            </div>                           
                            </div>
                </div>


                      </TableCell>
                      <TableCell align="right">{row.calories}</TableCell>
                      <TableCell align="right">{row.fat}</TableCell>
                      <TableCell align="right">{row.carbs}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
        </TabPanel>
      {teams&&
      teams.map((team,index)=>{return(
        <TabPanel value={value} index={index+1}>
        <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell><strong>氏名:</strong></TableCell>
                    <TableCell align="right"><strong>所属チーム</strong></TableCell>
                    <TableCell align="right"><strong>勤務時間数</strong></TableCell>
                    <TableCell align="right"><strong>勤務日数</strong></TableCell>
                    <TableCell align="right"><strong>所属</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                       <a href={`/employees/1`}>
                       {team.team_name}
                        </a>
                      </TableCell>
                      <TableCell align="right">{team.team_name}</TableCell>
                      <TableCell align="right">{row.fat}</TableCell>
                      <TableCell align="right">{row.carbs}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
        </TabPanel>
      )})
      }

      {teams ? <>
        <TabPanel value={value} index={teams.length+1}>
            <Form>
                <InputGroup className="mb-3">
                  <InputGroup.Text style={{width:'200px'}}>氏名</InputGroup.Text>
                  <div className="d-flex">
                    <Form.Control aria-label="First name" className="me-1" style={{ flex: 1 }} />
                  </div>
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text style={{width:'200px'}}>アカウント</InputGroup.Text>
                  <div className="d-flex">
                    <Form.Control aria-label="First name" className="me-1" style={{ flex: 1 }} />
                  </div>
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text style={{width:'200px'}}>パスワード</InputGroup.Text>
                  <div className="d-flex">
                    <Form.Control aria-label="First name" className="me-1" style={{ flex: 1 }} />
                  </div>
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text style={{width:'200px'}}>担当アカウント</InputGroup.Text>
                  <div className="d-flex">
                    <Form.Control aria-label="First name" className="me-1" style={{ flex: 1 }} />
                  </div>
                </InputGroup>
              <Button variant="primary" type="submit">
                保存
              </Button>
            </Form>
        </TabPanel>
      </> :
      <>
              <TabPanel value={value} index={1}>
            <Form>
                <InputGroup className="mb-3">
                  <InputGroup.Text style={{width:'200px'}}>氏名</InputGroup.Text>
                  <div className="d-flex">
                    <Form.Control aria-label="First name" className="me-1" style={{ flex: 1 }} />
                  </div>
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text style={{width:'200px'}}>アカウント</InputGroup.Text>
                  <div className="d-flex">
                    <Form.Control aria-label="First name" className="me-1" style={{ flex: 1 }} />
                  </div>
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text style={{width:'200px'}}>パスワード</InputGroup.Text>
                  <div className="d-flex">
                    <Form.Control aria-label="First name" className="me-1" style={{ flex: 1 }} />
                  </div>
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text style={{width:'200px'}}>担当アカウント</InputGroup.Text>
                  <div className="d-flex">
                    <Form.Control aria-label="First name" className="me-1" style={{ flex: 1 }} />
                  </div>
                </InputGroup>
              <Button variant="primary" type="submit">
                保存
              </Button>
            </Form>
        </TabPanel>
      </>
      }

        </>
    )
}

export default Employees