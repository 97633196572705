import { Alert, Box, Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Snackbar, TextField } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { addEmployee, updateEmployee } from "../../../services/EmployeesServices"
import Loading from "../../../components/loading/Loading"
import { styled } from '@mui/material/styles';
import { getTeam } from "../../../services/TeamService";


const Profile=(employee,{onChange})=>{
    const [isEdit, setIsEdit] = useState(false)
    const [teams,setTeams] = useState()
    const [team,setTeam] = useState()
    const [open, setOpen] = useState(false);
    const [updateMessage, setUpdateMessage] = useState(false);


    const nameRef = useRef(null);
    const visaStatusRef = useRef(null);
    const addressRef = useRef(null);
    const bloodRef = useRef(null);
    const phoneNumberRef = useRef(null);
    const birthDateRef = useRef(null);
    const genderRef = useRef(null);
    const fatherNameRef = useRef(null);
    const fatherAgeRef = useRef(null);
    const fatherOccupationRef = useRef(null);
    const mother1NameRef = useRef(null);
    const mother1AgeRef = useRef(null);
    const mother1OccupationRef = useRef(null);
    const mother2NameRef = useRef(null);
    const mother2AgeRef = useRef(null);
    const mother2OccupationRef = useRef(null);
    const departmentRef = useRef(null);

    const onChangeSave = async () => {
      const dateObject = new Date(birthDateRef.current.value);
      const formattedDateString = dateObject.toISOString().slice(0, 10);

        const inputData = {
          name: nameRef.current.value,
          visa_status: visaStatusRef.current.value,
          address: addressRef.current.value,
          blood: bloodRef.current.value,
          phone_number: phoneNumberRef.current.value,
          birth_date: formattedDateString,
          gender: genderRef.current.value,
          father_name: fatherNameRef.current.value,
          father_age: fatherAgeRef.current.value,
          father_occupation: fatherOccupationRef.current.value,
          mother1_name: mother1NameRef.current.value,
          mother1_age: mother1AgeRef.current.value,
          mother1_occupation: mother1OccupationRef.current.value,
          mother2_name: mother2NameRef.current.value,
          mother2_age: mother2AgeRef.current.value,
          mother2_occupation: mother2OccupationRef.current.value,
          department:departmentRef.current.value,
          team_id:team
        };
        try {
          const response = await updateEmployee(employee.employee.id,inputData);
         // setUpdateMessage(response)
          //setOpen(true);
          //setIsEdit(false)
          window.location.reload();
        } catch (err) {
          console.error(err);
        }
      };
      
      useEffect(()=>{
        const fetchTeam =async()=>{
          const response = await getTeam(employee.employee.company_id)
          setTeams(response) 
        }
        fetchTeam()
      },[])

      const handleSelectTeam =(e)=>{
        setTeam(e.target.value)
      }
      
      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

    return(
        
        <div className="khuyen-mai">
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {updateMessage}
          </Alert>
        </Snackbar>
        <div>
            {isEdit?
            <button className="title" id="add-employee-btn" onClick={()=>setIsEdit(false)}>閉じる</button>
            :<button className="title" id="add-employee-btn" onClick={()=>setIsEdit(true)}>編集</button>
            }
        </div>
        <div className="content">
            <ul>
            {isEdit&&
              <li>
                  <strong>氏名:</strong>
                <TextField id="standard-basic" disabled={!isEdit} placeholder={employee.employee.name} variant="standard" inputRef={nameRef} defaultValue={employee.employee.name} />
              </li>
            }

                <li>
                    <strong>在留資格:</strong>
                    {isEdit?
                      <TextField id="standard-basic" disabled={!isEdit} placeholder={employee.employee.visa_status} variant="standard" inputRef={visaStatusRef} defaultValue={employee.employee.visa_status} />
                    :
                    <>{employee.employee.visa_status}</>
                    }
                  </li>
                <li>
                    <strong>所属先:</strong>
                    {isEdit?
                    <TextField id="standard-basic" disabled={!isEdit} placeholder={employee.employee.department} variant="standard" inputRef={departmentRef} defaultValue={employee.employee.department} />                    
                    :
                    <>{employee.employee.department}</>
                    }
                </li>
                {(teams&&teams.length>0)&&
                <li>
                <strong>所属チーム:</strong>
                  <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={team}
                      defaultValue={employee.employee.team_id}
                      onChange={handleSelectTeam}
                    >
                     <MenuItem value={0}>
                        <em>なし</em>
                    </MenuItem>
                    {teams.map((team)=>{return(
                         <MenuItem value={team.id}>{team.team_name}</MenuItem>
                    )})
                      }
                    </Select>
                  </FormControl>
                </li>
              }
                <li>
                    <strong>住所:</strong>
                    {isEdit?
                      <TextField id="standard-basic" style={{ width: '60%' }}
                      disabled={!isEdit} placeholder={employee.employee.address} 
                      variant="standard" inputRef={addressRef} 
                      defaultValue={employee.employee.address} />
                    :
                    <>{employee.employee.address}</>
                    }
                </li>
                <li>
                    <strong>血液型:</strong>
                    {isEdit?
                    <TextField id="standard-basic" disabled={!isEdit}  placeholder={employee.employee.blood} variant="standard" inputRef={bloodRef} defaultValue={employee.employee.blood} />

                    :
                    <>{employee.employee.blood}</>
                    }
                </li>
                <li>
                    <strong>電話番号:</strong>
                    {isEdit?
                    <TextField id="standard-basic" disabled={!isEdit}  placeholder={employee.employee.phone_number} variant="standard" inputRef={phoneNumberRef} defaultValue={employee.employee.phone_number} />
                    :
                    <>{employee.employee.phone_number}</>
                    }
                </li>
                <li>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                    <strong>生年月日:</strong>
                    {isEdit ? (
                      <TextField
                        type="date"
                        id="standard-basic"
                        disabled={!isEdit}
                        placeholder={employee.employee.birth_date}
                        variant="standard"
                        inputRef={birthDateRef}
                        defaultValue={employee.employee.birth_date}
                      />
                    ) : (
                      <>{employee.employee.birth_date}</>
                    )}
                    </Grid>
                    <Grid item xs={4}>
                    <strong>性別:</strong>
                    {isEdit ? (
                    <TextField
                    id="standard-basic"
                    disabled={!isEdit}
                    placeholder={employee.employee.gender}
                    variant="standard"
                    inputRef={genderRef}
                    defaultValue={employee.employee.gender}
                  />
                    ) : (
                      <>{employee.employee.gender}</>
                    )}
                    </Grid>
                  </Grid>
                </li>
                <li>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                    <strong>父:</strong>
                    {isEdit ? (
                      <TextField id="standard-basic" disabled={!isEdit} placeholder={employee.employee.father_name} variant="standard" inputRef={fatherNameRef} defaultValue={employee.employee.father_name} />
                    ) : (
                      <>{employee.employee.father_name}</>
                    )}
                    </Grid>
                    <Grid item xs={4}>
                    <strong>年齢:</strong>
                    {isEdit ? (
                      <TextField id="standard-basic" disabled={!isEdit} placeholder={employee.employee.father_age} variant="standard" inputRef={fatherAgeRef} defaultValue={employee.employee.father_age} />
                    ) : (
                      <>{employee.employee.father_age}</>
                    )}
                    </Grid>
                    <Grid item xs={4}>
                    <strong>職業:</strong>
                    {isEdit ? (
                      <TextField id="standard-basic" disabled={!isEdit} placeholder={employee.employee.father_occupation} variant="standard" inputRef={fatherOccupationRef} defaultValue={employee.employee.father_occupation} />
                    ) : (
                      <>{employee.employee.father_occupation}</>
                    )}
                    </Grid>
                  </Grid>
                </li>
                <li>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                    <strong>母:</strong>
                    {isEdit ? (
                      <TextField id="standard-basic" disabled={!isEdit} placeholder={employee.employee.mother1_name} variant="standard" inputRef={mother1NameRef} defaultValue={employee.employee.mother1_name} />
                    ) : (
                      <>{employee.employee.father_name}</>
                    )}
                    </Grid>
                    <Grid item xs={4}>
                    <strong>年齢:</strong>
                    {isEdit ? (
                      <TextField id="standard-basic" disabled={!isEdit} placeholder={employee.employee.mother1_age} variant="standard" inputRef={mother1AgeRef} defaultValue={employee.employee.mother1_age} />
                    ) : (
                      <>{employee.employee.mother1_age}</>
                    )}
                    </Grid>
                    <Grid item xs={4}>
                    <strong>職業:</strong>
                    {isEdit ? (
                      <TextField id="standard-basic" disabled={!isEdit} placeholder={employee.employee.mother1_occupation} variant="standard" inputRef={mother1OccupationRef} defaultValue={employee.employee.mother1_occupation} />
                    ) : (
                      <>{employee.employee.mother1_occupation}</>
                    )}
                    </Grid>
                  </Grid>
                </li>
                <li>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                    <strong>妻:</strong>
                    {isEdit ? (
                      <TextField id="standard-basic" disabled={!isEdit} placeholder={employee.employee.mother2_name} variant="standard" inputRef={mother2NameRef} defaultValue={employee.employee.mother2_name} />
                    ) : (
                      <>{employee.employee.mother2_name}</>
                    )}
                    </Grid>
                    <Grid item xs={4}>
                    <strong>年齢:</strong>
                    {isEdit ? (
                      <TextField id="standard-basic" disabled={!isEdit} placeholder={employee.employee.mother2_age} variant="standard" inputRef={mother2AgeRef} defaultValue={employee.employee.mother2_age} />
                    ) : (
                      <>{employee.employee.mother2_age}</>
                    )}
                    </Grid>
                    <Grid item xs={4}>
                    <strong>職業:</strong>
                    {isEdit ? (
                      <TextField id="standard-basic" disabled={!isEdit} placeholder={employee.employee.mother2_occupation} variant="standard" inputRef={mother2OccupationRef} defaultValue={employee.employee.mother2_occupation} />
                    ) : (
                      <>{employee.employee.mother2_occupation}</>
                    )}
                    </Grid>
                  </Grid>
                </li>
                {isEdit&&<Button onClick={onChangeSave}>保存</Button>}
            </ul>      
        </div>
    </div>
    )
}

export default Profile