import Button from "react-bootstrap/Button";
import { InputGroup } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import UserList from "./UserList";
import { getAllCompany } from "../../../services/CompanyService";
import { AddUser } from "../../../services/UserService";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function User() {
  const [value, setValue] = useState(0);
  const nameRef = useRef();
  const companyRef = useRef();
  const loginAccRef = useRef();
  const passwordRef = useRef();

  const [rerender, setRerender] = useState("");
  const [data, setData] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const fetchEmployeeDetail = async () => {
      try {
        const data = await getAllCompany();
        setRerender("ok");
        setData(data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchEmployeeDetail();
  }, [rerender]);

  const [error, setError] = useState("");

  const onHandleSaveUSer = async () => {
    const password = passwordRef.current.value;
    if (password.length < 6) {
      alert("入力したパスワードが短いすぎます！");
      return;
    } else if (/\s/.test(password)) {
      alert("パスワードに空白が入っています！");
      return;
    } else {
      setError("");
    }

    const inputData = {
      name: nameRef.current.value,
      acc_name: loginAccRef.current.value,
      company: companyRef.current.value,
      password: password,
    };

    try {
      await AddUser(inputData);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="担当者一覧" {...a11yProps(0)} />
          <Tab label="担当者追加" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <UserList company={data} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <InputGroup className="mb-3">
          <InputGroup.Text style={{ width: "200px" }}>氏名</InputGroup.Text>
          <div className="d-flex">
            <input
              type="text"
              className="form-control"
              name="start_time"
              ref={nameRef}
            />
          </div>
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroup.Text style={{ width: "200px" }}>
            アカウント
          </InputGroup.Text>
          <div className="d-flex">
            <input
              type="text"
              className="form-control"
              name="start_time"
              ref={loginAccRef}
            />
          </div>
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroup.Text style={{ width: "200px" }}>
            パスワード
          </InputGroup.Text>
          <div className="d-flex">
            <input
              type="password"
              className="form-control"
              name="start_time"
              ref={passwordRef}
            />
          </div>
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroup.Text style={{ width: "200px" }}>
            担当アカウント
          </InputGroup.Text>
          <div className="d-flex">
            <select
              name="company_id"
              className="form-select"
              aria-label="Default select example"
              ref={companyRef}
            >
              <option value="">-- 会社 --</option>
              {data &&
                data.map((company, index) => {
                  return (
                    <option value={company.id} key={index}>
                      {company.company_full_name}
                    </option>
                  );
                })}
            </select>
          </div>
        </InputGroup>
        {error && <div className="invalid-feedback">{error}</div>}

        <Button variant="primary" onClick={onHandleSaveUSer}>
          追加
        </Button>
      </TabPanel>
    </>
  );
}

export default User;
