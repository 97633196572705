import { Box, Grid } from "@mui/material"
import { useContext, useEffect, useRef, useState } from "react"
import { getAllCompany } from "../../services/CompanyService"
import { AuthContext } from "../../context/AuthContext"
import { useLocation } from "react-router"
import Loading from "../../components/loading/Loading"

const Home = () =>{
  const [data,setData] = useState()
  const [xs,setXs] = useState(12)
  const {user} = useContext(AuthContext)
  const [className, setClassName] = useState("container px-lg-5");

  const [loading, setLoading] = useState(true)
  const timeout = useRef(null);

  useEffect(()=>{
    const fetchEmployeeDetail = async()=>{
      if(window.innerWidth < 1000){
        setClassName("")
      }
        try{
            const data = await getAllCompany(user.company)
            if(data.length > 1){
              setXs(6)
            }else{
              setClassName("")
            }
        }catch(err){
            console.error(err)
        }
    }
    fetchEmployeeDetail()
},[user.company])

    useEffect(() => {
      const fetchData = async () => {
        try {
          const data = await getAllCompany(user.company);
          setData(data);
          setLoading(false); // Dừng hiển thị loading khi có dữ liệu trả về
        } catch (err) {
          console.error(err);
          setLoading(false); // Dừng hiển thị loading nếu có lỗi xảy ra
        }
      };
    
      timeout.current = setTimeout(() => {
        setLoading(false);
      }, 10000);
    
      fetchData();
    
      return () => {
        clearTimeout(timeout.current);
      };
    }, []);

    if (loading) {
      return <Loading />;
    }

    return(
  <>
<div className="container-xxl py-5">
  <div className={className}>
    <div className="grid-container">
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
      {data && data.map((data) => {
        return (
      // JSX
      <Grid key={data.id} item xs={xs}>
        <div className="service-item d-flex flex-column justify-content-center text-center rounded">
          <div className="service-icon flex-shrink-0">
            <img src={data.company_image_link} alt={data.company_full_name} />
          </div>
          <h3 className="mb-3">{data.company_full_name}</h3>
          <h5 className="mb-3">
            <a href={`/company/${data.id}`}>従業員数　{data.NumberOfEmployees}人</a>
          </h5>
          <h5 className="mb-3">
            <a href={`/partner/${data.id}`}>パートナー企業数　{data.NumberOfPartners}</a>
          </h5>
        </div>
      </Grid>
        );
        })}
        {!data && (
          <div className="col-12 d-flex justify-content-center">
            <h3>会社情報がありません</h3>
          </div>
        )}
        </Grid>
    </Box>
    </div>
  </div>
</div>
  </>
    )
}

export default Home