import { IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { confirmAlert } from "react-confirm-alert";
import { deletePartner } from "../../../services/PertnerService";

const EditPartner = ({ data }) => {
  const handleDeleteClick = () => {
    confirmAlert({
      title: "確認",
      message: `${data.partnerName}さんを削除しますか？`,
      buttons: [
        {
          label: "確確",
          onClick: () => {
            deletePartner(data.id);
            window.location.reload();
          },
        },
        {
          label: "キャンセル",
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <div className="hnh-list-employees position-relative rounded overflow-hidden">
      <div className="portfolio-overlay">
        <div className="mt-auto">
          <div className="card">
            <Tooltip
              title="Delete"
              className="position-absolute top-0 end-0 mt-3 me-3"
            >
              <IconButton onClick={() => handleDeleteClick()}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <div className="card-body d-flex">
              <div>
                <h5 className="card-title">
                  <a href={`/company/${1}`}></a>
                </h5>
                <p className="card-text">{data.partnerName}</p>
                <p className="card-text">{data.partnerDes}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPartner;
