import axios from "axios";

const config = require(`../config/${process.env.REACT_APP_ENV}`);

const URL = config.API_URL;

export const getEmployees = async (company_id) => {
  try {
    const response = await axios.get(`${URL}/employees/${company_id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getEmployeeDetail = async (id, date) => {
  try {
    const response = await axios.post(`${URL}/employees/${id}`, date);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deleteEmployee = async (id) => {
  try {
    await axios.delete(`${URL}/employees/${id}`);
    return id;
  } catch (error) {
    console.error(error);
  }
};

export const addEmployee = async (employee) => {
  try {
    const response = await axios.post(
      `${URL}/company/${employee.company_id}`,
      employee
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const updateEmployee = async (id, employee) => {
  try {
    const response = await axios.put(`${URL}/employees/${id}`, employee);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const employeeUploadAvatar = async (id, avatar) => {
  try {
    const response = await axios.put(
      `${URL}/employees/${id}/avatarUpload`,
      avatar
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const addEmployeeWorkRecord = async (id, employee) => {
  try {
    const response = await axios.post(
      `${URL}/employees/employees_work_records/${id}`,
      employee
    );
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export const employeeDeleteWorkRecord = async (id) => {
  try {
    const response = await axios.delete(
      `${URL}/employees/employees_work_records/${id}`
    );
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export const updateEmployeeAvatar = async (id, avatar) => {
  try {
    const response = await axios.post(
      `${URL}/employees/${id}/avatarUpload`,
      avatar
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const employeeUploadFile = async (id, file) => {
  try {
    const response = await axios.post(`${URL}/employees/${id}/file`, file);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};
export const createLoginCode = async (id) => {
  try {
    const response = await axios.put(`${URL}/employees/loginCode/${id}`);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const AcceptRecord = async (id, data) => {
  try {
    const response = await axios.post(
      `${URL}/employees/AcceptRecord/${id}`,
      data
    );
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const deletePdf = async (id) => {
  try {
    const response = await axios.delete(`${URL}/employees/file/${id}`);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const createEmployeeNote = async (id, note) => {
  try {
    const response = await axios.post(
      `${URL}/employees/createEmployeeNote/${id}`,
      note
    );
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export const updateEmployeeNote = async (id, note) => {
  try {
    const response = await axios.put(
      `${URL}/employees/updateEmployeeNote/${id}`,
      note
    );
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export const deleteEmployeeNote = async (id) => {
  try {
    const response = await axios.delete(
      `${URL}/employees/deleteEmployeeNote/${id}`
    );
    return response.data;
  } catch (err) {
    console.error(err);
  }
};
