import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../../components/loading/Loading";
import { Button, IconButton, TextField, Tooltip } from "@mui/material";
import Profile from "./Profile";
import moment from "moment";
import {
  addEmployeeWorkRecord,
  createLoginCode,
  deletePdf,
  employeeUploadAvatar,
  employeeUploadFile,
  getEmployeeDetail,
} from "../../../services/EmployeesServices";
import AWS from "aws-sdk";
import { confirmAlert } from "react-confirm-alert";
import DeleteIcon from "@mui/icons-material/Delete";

const EmployeeDetail = () => {
  const [avatar, setAvatar] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [displayScope, setDisplayScope] = useState(true);
  const [editAcc, setIsEditAcc] = useState(true);

  const [classScope, setClassScope] = useState("container");
  const [employee, setEmployee] = useState();
  const [employeeFile, setEmloyeeFule] = useState();

  const [rerender, setRerender] = useState("");
  const [formData, setFormData] = useState({
    work_date: new Date().toISOString().substr(0, 10),
    start_time: "",
    end_time: "",
    overtime: "",
    work_content: "",
    travel_expense: 0,
    daily_wage: 0,
    hours_worked: "",
  });

  const fileRef = useRef();

  useEffect(() => {
    if (window.innerWidth < 1000) {
      setClassScope("");
    }
  }, []);

  const handleUpload = async (file, isAvatar, desc) => {
    const reader = new FileReader();
    reader.onload = () => {
      const fileName = file.name;
      const s3 = new AWS.S3({
        accessKeyId: "AKIA35SEH66RHTUSF5FR",
        secretAccessKey: "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",
        region: "ap-northeast-1",
      });

      const params = {
        Bucket: "akiyamastore",
        Key: `${employee.name}_${new Date()
          .toISOString()
          .slice(0, 10)}_${fileName}`,
        ContentType: file.type,
        Body: file,
        ACL: "public-read",
      };

      s3.upload(params, async (err, data) => {
        if (err) {
          console.log(err);
          return;
        }
        const inputData = {
          file_name: data.Location,
          description: desc,
          isAvatar: isAvatar,
        };

        if (data.Location) {
          await employeeUploadFile(employee.id, inputData);
          setRerender("render2");
          setSelectedFile();
          setAvatar();
        }
      });
    };

    reader.readAsDataURL(file);
  };

  const handleSelectFile = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleClick = () => {
    const isAvatar = false;
    const desc = fileRef.current.value;
    if (selectedFile) {
      handleUpload(selectedFile, isAvatar, desc);
    }
  };

  const handleSelectAvatar = (event) => {
    const file = event.target.files[0];
    setAvatar(file);
  };
  const handleClickSaveAvatar = () => {
    if (avatar) {
      handleUploadAvatar(avatar);
    }
  };

  const handleUploadAvatar = async (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      const fileName = file.name;
      const s3 = new AWS.S3({
        accessKeyId: "AKIA35SEH66RHTUSF5FR",
        secretAccessKey: "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",
        region: "ap-northeast-1",
      });

      const params = {
        Bucket: "akiyamastore",
        Key: `${employee.name}_${new Date()
          .toISOString()
          .slice(0, 10)}_${fileName}`,
        ContentType: file.type,
        Body: file,
        ACL: "public-read",
      };

      s3.upload(params, async (err, data) => {
        if (err) {
          console.log(err);
          return;
        }
        const inputData = {
          avatar: data.Location,
        };
        if (data.Location) {
          await employeeUploadAvatar(id, inputData);
          fetchEmployeeDetail();
          setSelectedFile();
          setAvatar();
        }
      });
    };

    reader.readAsDataURL(file);
  };

  const formRef = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault(); // Ngăn chặn gửi form mặc định
    setRerender("render");
  };

  const fetchEmployeeDetail = async () => {
    try {
      const data = await getEmployeeDetail(id);
      setEmployee(data.employees);
      setData(data);
      setEmloyeeFule(data.employeeFile);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchEmployeeDetail();
    setRerender("");
  }, [rerender]);

  const HandleRerender = () => {
    setRerender("childrent rerender");
  };

  const handleLoginAcc = async () => {
    const response = await createLoginCode(id);
    setIsEditAcc(!editAcc);
    alert(response.message);
    window.location.reload();
  };

  const onDeleteEmployee = async (id) => {
    console.log(id);
    await deletePdf(id);
    fetchEmployeeDetail();
  };

  const handleDeleteClick = (id) => {
    confirmAlert({
      title: "確認",
      message: `本当に削除しますか？`,
      buttons: [
        {
          label: "確確",
          onClick: () => {
            onDeleteEmployee(id);
          },
        },
        {
          label: "キャンセル",
          onClick: () => {},
        },
      ],
    });
  };

  if (!data) return <Loading />;

  return (
    <>
      <div className="hnh-em py-5 profile-section">
        <div className="container">
          {data && (
            <div className="row">
              <div className="col-md-3 col-xs-12 col hnh-image">
                {data.employees.avatar ? (
                  <>
                    <img
                      src={`${data.employees.avatar}`}
                      alt="Employee Photo"
                    />
                  </>
                ) : (
                  <>
                    <img src="../img/noavatar.png" alt="Employee Photo" />
                  </>
                )}
                <div className="input-group mb-3">
                  <input
                    onChange={handleSelectAvatar}
                    className="form-control"
                    name="work_date"
                    type="file"
                    accept="image/png,image/jpeg,image/jpg"
                  />
                  <button
                    disabled={avatar ? false : true}
                    type="submit"
                    className="btn btn-outline-secondary"
                    id="button-addon2"
                    onClick={handleClickSaveAvatar}
                  >
                    保存
                  </button>
                </div>
                <div className="khuyen-mai">
                  {employee.loginCode ? (
                    <>ログインコード： {employee.loginCode}</>
                  ) : (
                    <>
                      <button
                        className="title"
                        id="add-employee-btn"
                        onClick={handleLoginAcc}
                      >
                        ログインコード生成
                      </button>
                      <p>コードなし</p>
                    </>
                  )}
                </div>
              </div>
              <div className="col-md-9 col-xs-12 col hnh-details">
                <h2>氏名：{employee.name}</h2>
                <a
                  className="btn btn-outline-secondary"
                  href={`/employeeworks/${employee.id}`}
                >
                  稼働状況
                </a>
                <a
                  className="btn btn-outline-secondary active"
                  href={`/employees/${employee.id}`}
                >
                  社員プロフィール
                </a>
                <Profile employee={employee} onChange={HandleRerender} />
              </div>
            </div>
          )}
        </div>
      </div>
      {displayScope ? (
        <div className="container">
          <div className="file-uploadform">
            <div className="col-xs-12">
              <form ref={formRef} onSubmit={handleSubmit}>
                <table className="table hnh-table-em">
                  <thead>
                    <tr>
                      <th scope="col">資料</th>
                      <th scope="col">アプロード時間</th>
                      <th scope="col">備考</th>
                      <th scope="col">操作</th>
                    </tr>
                  </thead>
                  <tbody>
                    {employeeFile &&
                      employeeFile.map((work, index) => {
                        return (
                          <tr>
                            <th scope="row">
                              <a href={work.file_name} target="_blank">
                                資料{index + 1}
                              </a>
                            </th>
                            <td>
                              {moment(work.createAt).format("YYYY/MM/DD HH:mm")}
                            </td>
                            <td>{work.description}</td>
                            <td>
                              <IconButton
                                onClick={() => handleDeleteClick(work.id)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </td>
                          </tr>
                        );
                      })}
                    <tr>
                      <th scope="row">
                        <input
                          onChange={handleSelectFile}
                          className="form-control"
                          name="work_date"
                          type="file"
                        />
                      </th>
                      <td></td>
                      <td>
                        <input
                          className="form-control"
                          name="work_date"
                          ref={fileRef}
                          type="text"
                        />
                      </td>
                      <td>
                        <div className="input-group mb-3">
                          <button
                            className="btn btn-outline-secondary"
                            onClick={handleClick}
                          >
                            保存
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div className={`${classScope}`}></div>
      )}
    </>
  );
};

export default EmployeeDetail;
