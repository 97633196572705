import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import './Loading.css';

function Loading() {
  return (
    <div className="container">
      <div className="spinner">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <p className="loading-text">Loading...</p>
      </div>
    </div>
  );
}

export default Loading;
