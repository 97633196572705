import { useEffect, useRef, useState } from "react";
import { addPartner, getPartnerList } from "../../../services/PertnerService";
import Loading from "../../../components/loading/Loading";
import Form from "react-bootstrap/Form";
import { Tabs, Tab, Box } from "@mui/material";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { InputGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useParams } from "react-router";
import EditPartner from "./EditPartner";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Partner = () => {
  const { id } = useParams();
  const partnerDesRef = useRef();
  const partnerNameRef = useRef();
  const [partner, setPartner] = useState();
  const [loading, setLoading] = useState(false);
  const [isAddPartner, setIsAddPartner] = useState(false);
  const nameRef = useRef(null);
  const [rerender, setRerender] = useState("");
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const fetchPartner = async () => {
      const response = await getPartnerList(id);
      setPartner(response);
    };

    fetchPartner();
  }, []);

  const onHandleAddPartner = async () => {
    const inputData = {
      name: partnerNameRef.current.value,
      des: partnerDesRef.current.value,
    };
    try {
      await addPartner(id, inputData);
      setRerender("response");
      setIsAddPartner(false);
      window.location.reload();
    } catch (err) {
      return err;
    }
  };

  if (loading) return <Loading />;
  return (
    <>
      <div className="container-xxl p-0">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="パートナー一覧" {...a11yProps(0)} />
            <Tab label="パートナー追加" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          {partner &&
            partner.map((row) => (
              <>
                <EditPartner data={row} />
              </>
            ))}
        </TabPanel>
        <TabPanel value={value} index={1}>
          <InputGroup className="mb-3">
            <InputGroup.Text style={{ width: "200px" }}>
              パートナー名
            </InputGroup.Text>
            <div className="d-flex">
              <Form.Control
                ref={partnerNameRef}
                aria-label="First name"
                className="me-1"
                style={{ flex: 1 }}
              />
            </div>
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text style={{ width: "200px" }}>
              パートナー詳細
            </InputGroup.Text>
            <div className="d-flex">
              <Form.Control
                ref={partnerDesRef}
                aria-label="First name"
                className="me-1"
                style={{ flex: 1 }}
              />
            </div>
          </InputGroup>
          <Button variant="primary" onClick={onHandleAddPartner}>
            保存
          </Button>
        </TabPanel>
      </div>
    </>
  );
};

export default Partner;
