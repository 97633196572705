import { useContext, useRef, useState } from "react";
import "./LoginForm.css";
import { Login } from "../../services/UserService";
import { useNavigate } from "react-router";
import { AuthContext } from "../../context/AuthContext";
import ButtonLoading from "../../components/loading/ButtonLoading";

const LoginForm = () => {
  const nameRef = useRef();
  const passRef = useRef();
  const [err, setErr] = useState();
  const [displayScope, setDisplayScope] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);

  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    setButtonLoading(true);
    e.preventDefault();
    const inputData = {
      acc_name: nameRef.current.value,
      password: passRef.current.value,
    };
    try {
      return new Promise(async (resolve, reject) => {
        const response = await Login(inputData);
        setErr("");
        if (response.data.acc_name) {
          login(response.data.id);
          setErr("ログイン成功");
          setTimeout(() => {
            navigate("/", { state: response.data });
            window.location.reload();
          }, 1000);
        } else {
          setErr(response.data);
          setButtonLoading(false);
        }
      });
    } catch (err) {
      setErr("アカウント又はパスワードが間違っています");
      setButtonLoading(false);
    }
  };

  return (
    <>
      {displayScope ? (
        <>
          <div className="form-container">
            <form className="login-form">
              <h2>ログイン</h2>
              <p style={{ color: "red" }}>{err}</p>
              <div>
                <label htmlFor="username">Username:</label>
                <input
                  style={{ border: "1px solid blue" }}
                  type="text"
                  id="username"
                  name="username"
                  ref={nameRef}
                  required
                />
              </div>
              <div>
                <label htmlFor="password">Password:</label>
                <input
                  style={{ border: "1px solid blue" }}
                  type="password"
                  ref={passRef}
                  name="password"
                  required
                />
              </div>
              <button onClick={handleSubmit}>
                {buttonLoading ? (
                  <>
                    <ButtonLoading />
                  </>
                ) : (
                  <>ログイン</>
                )}
              </button>
            </form>
          </div>
        </>
      ) : (
        <>
          <div className="form-container2">
            <form className="login-form2">
              <h2>ログイン</h2>
              <p style={{ color: "red" }}>{err}</p>
              <div>
                <label htmlFor="username">Username:</label>
                <input
                  style={{ border: "1px solid blue" }}
                  type="text"
                  id="username"
                  name="username"
                  ref={nameRef}
                  required
                />
              </div>

              <div>
                <label htmlFor="password">Password:</label>
                <input
                  style={{ border: "1px solid blue" }}
                  type="password"
                  ref={passRef}
                  name="password"
                  required
                />
              </div>
              <button onClick={handleSubmit}>
                {buttonLoading ? (
                  <>
                    <ButtonLoading />
                  </>
                ) : (
                  <>ログイン</>
                )}
              </button>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default LoginForm;
