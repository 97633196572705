import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

function ButtonLoading() {
  return (

        <Spinner style={{width:"10px", height:"5px"}} animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
  );
}

export default ButtonLoading;
