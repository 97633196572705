import { useContext, useState } from "react"
import { Button, Container, Nav, Navbar } from "react-bootstrap"
import { AuthContext } from "../context/AuthContext"
import { Navigate, useNavigate } from "react-router"

const Header =()=>{
    const {user} = useContext(AuthContext)
    const [redirectToLogin, setRedirectToLogin] = useState(false);
    const naviagate = useNavigate()
    const HandleLogOut=()=>{
        localStorage.removeItem("user");
        setRedirectToLogin(true);
        naviagate('/login')
        window.location.reload()
    }


    return(
      <>
      <div className="container-xxl position-relative p-0">
      <nav className="navbar navbar-expand-md navbar-primary sticky-top">
            <div className="container-fluid">
         <a href="/" className="navbar-brand p-0">
            <h1 className="m-0"><span className="fs-5">AKIYAMA BOSS</span></h1>
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span className="fa fa-bars"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
        <div className="navbar-nav ms-auto py-0">

            {user?<>
                <p  className="nav-item nav-link" >
                {user.name}    
                </p>
            {user.company > 0 ? 
            <>
                <Button  className="btn btn-secondary text-light rounded-pill py-2 px-4 ms-3" onClick={HandleLogOut}>ログアウト</Button>
            </>
                :
            <>
                <a href="/company" className="nav-item nav-link">会社管理</a>
                 <a href="/user" className="nav-item nav-link">担当社管理</a>
                <Button  className="btn btn-secondary text-light rounded-pill py-2 px-4 ms-3" onClick={HandleLogOut}>ログアウト</Button>

            </>
        }
        </>
            :<>
                <a href="/login" className="btn btn-secondary text-light rounded-pill py-2 px-4 ms-3">ログイン</a>
            </>}
            </div>

        </div>
    </div>
</nav>

</div>
      </>

    )
}

export default Header