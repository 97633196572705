import axios from 'axios';

const config = require(`../config/${process.env.REACT_APP_ENV}`);

const URL = config.API_URL

export const getAllTeam = async () => {
  try {
    const response = await axios.get(`${URL}/company`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const addTeam = async (team) => {
    try {
      const response = await axios.post(`${URL}/team/`, team);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  export const getTeam = async (id) => {
    try {
      const response = await axios.get(`${URL}/team/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  export const updateTeam = async (id,team) => {
    try {
      const response = await axios.put(`${URL}/team/${id}`,team);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  export const deleteTeam = async (id) => {
    try {
      const response = await axios.delete(`${URL}/team/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };