import { getAllCompany } from '../../../services/CompanyService';
import { useEffect, useRef, useState } from 'react';
import { IconButton, Table, TableBody, TextField } from '@mui/material';

import EditCompany from './EditCompany';
import AddCompany from './AddCompany';
import Loading from '../../../components/loading/Loading';

//https://ams-keibi.jp/images/asahi_lease.jpg
//https://ams-keibi.jp/images/syuei.jpg
//https://asahi-lease.jp/work/2222-5-158.jpg

export default function Company() {
  const [value, setValue] = useState(0);
  const [rerender, setRerender]= useState("")
  const [data,setData] = useState(null)
  const [editName,setEditName] = useState([])
  const timeout = useRef(null);
  const [loading, setLoading] = useState(true)

  useEffect(()=>{
    const fetchEmployeeDetail = async()=>{
        try{
            const data = await getAllCompany()
            setRerender("ok")
            setData(data)
            setLoading(false);

        }catch(err){
            console.error(err)
            setLoading(false);
        }
    }
    timeout.current = setTimeout(() => {
      setLoading(false);
    }, 10000);
  
    fetchEmployeeDetail()
    return () => {
      clearTimeout(timeout.current);
    };
},[rerender])

if (loading) {
  return <Loading />;
}

  return (
    <>
    <div className="container-xxl p-0">
        <div className='container'>
        <Table>
        <TableBody>
        {data&&data.map((row,index)=>{
            return(
               <>
                  <EditCompany company={row} index={index}/>
               </>
            )
        })}
     </TableBody>
     <AddCompany />
     </Table>
        </div>
        </div>
    </>
  );
}