import axios from "axios";

const config = require(`../config/${process.env.REACT_APP_ENV}`);

const URL = config.API_URL;

export const getAllUser = async () => {
  try {
    const response = await axios.get(`${URL}/auth`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getUser = async (id) => {
  try {
    const response = await axios.get(`${URL}/auth/${id}`);
    localStorage.setItem("user", JSON.stringify(response.data));
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const Login = async (user) => {
  try {
    const response = await axios.post(`${URL}/auth/login`, user);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const AddUser = async (user) => {
  try {
    const response = await axios.post(`${URL}/auth`, user);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const updateUser = async (id, user) => {
  try {
    const response = await axios.put(`${URL}/auth/${id}`, user);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
